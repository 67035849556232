// TODO
// - would ideally have a way to cancel in-progress getRows calls, e.g. using the signal for fetch requests
// - retry after error?

import { useEffect, useMemo, useState } from "react";
import { TableSortDir } from "../types";

export type AsyncRowOptions<Columns extends String> = {
    sortColumn?: Columns;
    sortDirection?: TableSortDir;

    page?: number;
    itemsPerPage?: number;
};

export type GetAsyncRows<RowItemType, Columns extends string> = (props: AsyncRowOptions<Columns>) => Promise<RowItemType[]>;

export default function useAsyncRows<RowItemType, Columns extends string, ErrorType = any>(
    getRows:  GetAsyncRows<RowItemType, Columns>,
    props: AsyncRowOptions<Columns> = {}
) {
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<ErrorType | undefined>();
    const [currentRows, setCurrentRows] = useState<RowItemType[]>([]);
    const {sortColumn, sortDirection, page, itemsPerPage} = props;

    useEffect(() => {
        let cancelled = false;

        const loadRows = async () => {
            setLoading(true);
            setError(undefined);

            try {
                const rows = await getRows(props);
                
                if(cancelled) {
                    return;
                }

                setLoading(false);
                setCurrentRows(rows);
            }
            catch(e) {
                setError(e as ErrorType);
                setLoading(false);
            }
        };

        loadRows();

        return () => {
            cancelled = true;
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sortColumn, sortDirection, page, itemsPerPage, getRows])

    return useMemo(() => ([loading, currentRows, error] as const), [loading, currentRows, error]);
}