import classNames from "classnames";
import { CSSProperties, ForwardedRef, forwardRef, useMemo } from "react";

import useStyles from './styles';
import { TableDisplayProps, TableHeadDisplayProps, TableBodyDisplayProps, TableFootDisplayProps, TableRowDisplayProps, TableTDDisplayProps, TableTHDisplayProps, TableCaptionDisplayProps } from "./types";

import SortButton from "./SortButton";


function TableDisplay({children, columns, style: _style, className, ...rest}: TableDisplayProps, ref: ForwardedRef<HTMLTableElement>) {
    const classes = useStyles();
    const style = useMemo(() => ({..._style, '--table-columns': columns}), [_style, columns])

    return <table className={classNames(className, classes.table)} style={style} {...rest} ref={ref}>{children}</table>
}


function Head({children, className, ...rest}: TableHeadDisplayProps, ref: ForwardedRef<HTMLTableSectionElement>) {
    const classes = useStyles();

    return <thead className={classNames(className, classes.head)} {...rest} ref={ref}>{children}</thead>
}

function Body({children, className, ...rest}: TableBodyDisplayProps, ref: ForwardedRef<HTMLTableSectionElement>) {
    const classes = useStyles();

    return <tbody className={classNames(className, classes.body)} {...rest} ref={ref}>{children}</tbody>
}

function Foot({children, className, ...rest}: TableFootDisplayProps, ref: ForwardedRef<HTMLTableSectionElement>) {
    const classes = useStyles();

    return <tfoot className={classNames(className, classes.foot)} {...rest} ref={ref}>{children}</tfoot>
}

function Row({children, className, active, selectable, ...rest}: TableRowDisplayProps, ref: ForwardedRef<HTMLTableRowElement>) {
    const classes = useStyles();

    return <tr className={classNames(className, classes.row, active && classes.active, selectable && classes.selectable)} {...rest} ref={ref}>{children}</tr>
}

function TD({children, className, sort, colSpan, horizontalAlign, loading, allowContentOverflow, style: _style, ...rest}: TableTDDisplayProps, ref: ForwardedRef<HTMLTableCellElement>) {
    const classes = useStyles();
    const style: CSSProperties | undefined = useMemo(() => {
            return (colSpan !== undefined && colSpan > 1 )
                ? ({..._style, "--table-cell-columns": colSpan > 1 ? colSpan : undefined}) as any
                : _style
        }, 
    [_style, colSpan]);

    return <td 
        className={classNames(
            className, 
            classes.td, 
            sort && classes[`sort-${sort}`],
            horizontalAlign && classes[`horizontalAlign-${horizontalAlign}`],
            loading && classes.loading,
            allowContentOverflow && classes.allowContentOverflow,
        )}
        colSpan={colSpan}
        {...rest} 
        ref={ref}
        style={style}
    >{children}</td>
}

function TH({children, className, "aria-sort": ariaSort, sort, horizontalAlign, allowContentOverflow, ...rest}: TableTHDisplayProps, ref: ForwardedRef<HTMLTableCellElement>) {
    const classes = useStyles();

    return <th
        className={classNames(
            className, 
            classes.th, 
            sort && classes[`sort-${sort}`],
            horizontalAlign && classes[`horizontalAlign-${horizontalAlign}`],
            allowContentOverflow && classes.allowContentOverflow,
        )} 
        aria-sort={ariaSort || (sort ? sort === "asc" ? "ascending" : "descending" : undefined)} 
        {...rest} 
        ref={ref}
    >{children}</th>
}

//?
function Caption({children, className, ...rest}: TableCaptionDisplayProps, ref: ForwardedRef<HTMLTableCaptionElement>) {
    const classes = useStyles();

    return <caption className={classNames(className, classes.caption)} {...rest} ref={ref}>{children}</caption>
}


export default Object.assign(forwardRef<HTMLTableElement, TableDisplayProps>(TableDisplay), {
    Head:   forwardRef<HTMLTableSectionElement, TableHeadDisplayProps>(Head),
    Body:   forwardRef<HTMLTableSectionElement, TableBodyDisplayProps>(Body),
    Foot:   forwardRef<HTMLTableSectionElement, TableFootDisplayProps>(Foot),
    Row:    forwardRef<HTMLTableRowElement, TableRowDisplayProps>(Row),
    TD:     forwardRef<HTMLTableCellElement, TableTDDisplayProps>(TD),
    TH:     forwardRef<HTMLTableCellElement, TableTHDisplayProps>(TH),
    Caption: forwardRef<HTMLTableCaptionElement, TableCaptionDisplayProps>(Caption),
    SortButton,
});