import makeStyles from '@mui/styles/makeStyles';
import {Theme} from 'hsi/types/theme';

export default makeStyles<Theme>(() => ({
    loadingBody: {
        position: 'relative',
        minHeight: 'var(--table-row-minHeight)',
    },
    loadingRow: {
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
    },
    loadingCell: {
        
    },
}));