import classNames from "classnames";
import { polymorphicForwardRef } from "hsi/types/react-polymorphic";
import OverflowTooltip, {OverflowTooltipProps} from "../Tooltip/OverflowTooltip";
import { useMemo } from "react";

type TruncateWithTooltipProps = {
    className?: string;
    portal?: boolean;
    tooltipProps?: Omit<OverflowTooltipProps, 'children'>;
};

const TruncateWithTooltip = polymorphicForwardRef<TruncateWithTooltipProps, 'div'>(function TruncateWithTooltip({children, as: Component = 'div', tooltipProps, className, ...rest}, ref) {
    const tooltipPropsPlusContent = useMemo(() => ({
        ...tooltipProps,
        tooltip: tooltipProps?.tooltip ?? <Component className={className} {...rest}>{children}</Component>
    }), [Component, children, className, rest, tooltipProps]);

    return <OverflowTooltip {...tooltipPropsPlusContent}>
        <Component className={classNames(className, 'truncate')} ref={ref} {...rest}>
            {children}
        </Component>
    </OverflowTooltip>;
});

export default TruncateWithTooltip;