import { forwardRef, useMemo, ReactElement, ForwardedRef } from "react";
import { TableSortDir } from "../../types";

// Components
import IconRouter from "hsi/components/IconRouter";

// Other
import useStyles from './styles';
import classNames from "classnames";
import { T } from "hsi/i18n";
import TruncateWithTooltip from "hsi/components/TruncateWithTooltip";

// Types

type SortButtonBaseProps = {
    sort?: TableSortDir;
    truncateTextContent?: boolean;
};

export type SortButtonActiveProps = SortButtonBaseProps & JSX.IntrinsicElements['button'];
export type SortButtonInactiveProps = SortButtonBaseProps & Omit<JSX.IntrinsicElements['div'], 'onClick'>;

type SortButtonType = {
    (props: SortButtonActiveProps & {ref?: ForwardedRef<HTMLElement>}): (ReactElement | null);
    (props: SortButtonInactiveProps & {ref?: ForwardedRef<HTMLElement>}): (ReactElement | null);
}


//The component
const SortButton = forwardRef<HTMLElement, SortButtonActiveProps & SortButtonInactiveProps>(function SortButton({children, className, sort, truncateTextContent, onClick, ...rest}, ref) {
    const classes = useStyles();

    const content = useMemo(() => <>
        {truncateTextContent 
            ? <TruncateWithTooltip as="span" className={classes.content}>{children}</TruncateWithTooltip>
            : <span className={classes.content}>{children}</span>}
        {!!sort && <>
            <IconRouter aria-hidden className={classes.icon} name={`arrow-drop-${getArrowDir(sort)}`} />
            <span className="offscreen">{sort === 'asc' ? T(`managedTable.sortedColumnAsc`) : T(`managedTable.sortedColumnDesc`)}</span>
        </>}
    </>, [children, classes.content, classes.icon, sort, truncateTextContent]);

    if(onClick) {
        return <button className={classNames(classes.sortButton, className, classes.active)} onClick={onClick} {...rest} ref={ref as any}>
            {content}
        </button>
    }

    return <div className={classNames(classes.sortButton, className)} {...rest} ref={ref as any}>
        {content}
    </div>
});

export default SortButton as SortButtonType;



function getArrowDir(sort: TableSortDir) {
    return sort === 'desc' ? 'down' : 'up';
}