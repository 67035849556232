/**
 * Sorting: DONE
 * clickable rows: DONE
 * pagination: DONE
 * formatting data: DONE
 * truncating values + tooltips? (including header): DONE
 * indicate loading data?
 */


//components
import { Card } from '@mui/material';
import PaginationButtons from 'hsi/components/PaginationButtons';
import ManagedTable from 'hsi/components/table/ManagedTable';
import useAsyncRows, { GetAsyncRows } from 'hsi/components/table/ManagedTable/useAsyncRows';
import useFormatRows from 'hsi/components/table/ManagedTable/useFormatRows';
import useManagedPagination from 'hsi/components/table/ManagedTable/useManagedPagination';
import useManagedSort from 'hsi/components/table/ManagedTable/useManagedSort';
import usePaginateRows from 'hsi/components/table/ManagedTable/usePaginateRows';
import useSortRows, { getSortCompareFunc } from 'hsi/components/table/ManagedTable/useSortRows';
import TableDisplay from 'hsi/components/table/TableDisplay';
import delay from 'hsi/utils/delay';
import { ReactNode, useCallback } from 'react';

type ExampleItemType = {id: number, name: ReactNode, value: number, sortVal: number, actions?: never}

const exampleItems = [
    {id: 1, name: 'Hello', value: Math.random(), sortVal: 0},
    {id: 2, name: 'World', value: Math.random(), sortVal: 2},
    {id: 3, name: 'Fish', value: Math.random(), sortVal: 0},
    {id: 4, name: 'Zoo', value: Math.random(), sortVal: 5},
    {id: 5, name: <>Javascript for <i>Dummies</i></>, value: Math.random(), sortVal: 0},
    {id: 6, name: 'War and Peace', value: Math.random(), sortVal: 0},
    {id: 7, name: 'Catcher in the Rye', value: Math.random(), sortVal: 4},
    {id: 8, name: 'Of Mice and Men', value: Math.random(), sortVal: 0},
    {id: 9, name: 'Homer\'s Odyssey', value: Math.random(), sortVal: 0},
    {id: 10, name: 'Dune', value: Math.random(), sortVal: 1},
    {id: 11, name: 'Dune 2', value: Math.random(), sortVal: 0},
    {id: 12, name: 'Dune 20', value: Math.random(), sortVal: 3},
] as ExampleItemType[];

const columns = [
    {
        name: 'name',
        label: 'Name of the thing goes here',
        width: '2fr',
        horizontalAlign: 'start',
        truncateHeaderLabelText: true,
        sortable: true,
        //example custom sorting function
        // sortType: (a: ExampleItemType, b: ExampleItemType) => {
        //     return a.sortVal - b.sortVal;
        // },

        //Example formatting function
        format: (row: ExampleItemType) => {
            return <a href={`#${row.sortVal}`}>{row.name}</a>
        },
    },
    {
        name: 'value',
        label: <>Value that <u>doesn't</u> really <b>mean</b> <i>anything</i></>,
        width: '50px',
        truncateHeaderLabelText: true,
        sortable: true,
        sortType: 'numeric',
        defaultSortDir: 'desc',
        format: (row: ExampleItemType) => {
            return Math.floor(row.value * 100) + '%';
        }
    },
    {
        name: 'actions',
        label: 'Stuff you can do like',
        width: '50px',
        truncateHeaderLabelText: true,
        truncateCellText: true,
        format: () => {
            return <>click <b>here</b> I guess?</>
        },
        //thComponent: SimpleTruncatedTH,
    }
] as const;

type Columns = typeof columns[number]['name'];


export default function TestBed() {
    const itemsPerPage = 5;

    const {onSortClick, sortDirection, sortColumn} = useManagedSort<ExampleItemType, Columns>(columns);
    const {page, pages, setPage} = useManagedPagination(exampleItems.length, itemsPerPage);

    //Simple local data example
    const sortedRowData = useSortRows(exampleItems, columns, sortColumn, sortDirection);
    const paginatedRowData = usePaginateRows(sortedRowData, page, itemsPerPage);
    const formattedRows = useFormatRows(paginatedRowData, columns);

    //Async data example
    const {onSortClick: onAsyncSortClick, sortDirection: asyncSortDir, sortColumn: asyncSortColumn} = useManagedSort<ExampleItemType, Columns>(columns);
    const {page: asyncPage, pages: asyncPages, setPage: asyncSetPage} = useManagedPagination(exampleItems.length, itemsPerPage);

    const getAsyncRows = useCallback<GetAsyncRows<ExampleItemType, Columns>>(async ({sortColumn, sortDirection, page, itemsPerPage}) => {
        await delay(1000 + Math.random() * 2000);

        page = page ?? 1;
        itemsPerPage = itemsPerPage ?? 1;

        if(page === 0) {
            return [];
        }

        const clonedRows = [...exampleItems];

        if(sortColumn) {
            const compareFn = getSortCompareFunc<ExampleItemType>(columns, sortColumn, sortDirection);
            
            compareFn && clonedRows.sort(compareFn);
        }
        
        return clonedRows.slice((page - 1) * itemsPerPage, page * itemsPerPage);
    }, []);

    const [loading, asyncRows, asyncRowsError] = useAsyncRows(
        getAsyncRows, 
        {page: asyncPage, itemsPerPage, sortColumn: asyncSortColumn, sortDirection: asyncSortDir}
    );

    const formattedAsyncRows = useFormatRows(asyncRows, columns);

    if(asyncRowsError) {
        console.log('asyncRowsError: ', asyncRowsError);
    }

    return <div>
        <h1>Tables</h1>
        <Card style={{margin: '8px', padding: '8px'}}>
        <h2>Table display components example</h2>
            <TableDisplay columns="repeat(2, auto)">
                <TableDisplay.Head>
                    <TableDisplay.Row>
                        <TableDisplay.TH sort="desc" horizontalAlign='end'>
                            <TableDisplay.SortButton sort='desc'>
                                Name
                            </TableDisplay.SortButton>
                        </TableDisplay.TH>
                        <TableDisplay.TH>Value</TableDisplay.TH>
                    </TableDisplay.Row>
                </TableDisplay.Head>
                <TableDisplay.Body>
                    {exampleItems.map(({id, name, value}) => <TableDisplay.Row active={value > 0.75} selectable key={id}>
                        <TableDisplay.TD sort="asc" horizontalAlign='start'>{name}</TableDisplay.TD>
                        <TableDisplay.TD>{value}</TableDisplay.TD>
                    </TableDisplay.Row>)}
                    
                </TableDisplay.Body>
            </TableDisplay>
            <br />
            <br />
            <h2>Managed table components example</h2>
            <ManagedTable<Columns>
                columns={columns} 
                rowData={formattedRows} 
                caption="Example managed table"
                //loading
                rowSelectable
                onRowClick={(index, event) => {
                    console.log('onRowClick: ', index, event);
                }}
                onSortClick={onSortClick}
                sortColumn={sortColumn} 
                sortDirection={sortDirection} 
            />
            <PaginationButtons page={page} pages={pages} setPage={setPage} />
            <br />
            <br />
            <h2>Managed table components async example</h2>
            <ManagedTable<Columns>
                columns={columns} 
                rowData={formattedAsyncRows} 
                caption="Example async managed table"
                loading={loading}
                rowSelectable
                onRowClick={(index, event) => {
                    console.log('onRowClick: ', index, event);
                }}
                onSortClick={onAsyncSortClick}
                sortColumn={asyncSortColumn} 
                sortDirection={asyncSortDir} 
            />
            <PaginationButtons page={asyncPage} pages={asyncPages} setPage={asyncSetPage} />
            <br />
            <br />
        </Card>
    </div>
}
