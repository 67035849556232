import pick from 'lodash/pick';
import isEmpty from 'lodash/isEmpty';
import {saveAs} from 'file-saver';

//Services
import {
    exportMentionsToPDF as exportMentionsToPDFService,
    exportReportAsPDF,
} from 'hsi/services/exportService';
import {
    loadMentionsCursoredPagination,
    loadSavedSearchMentions,
} from 'hsi/services/mentionsService';

//Actions
import {showNotification, hideNotification} from 'hsi/actions/notificationsActions';
import {loadAllSearchResults} from 'hsi/actions/resultsActions';
import {persistActiveQuery} from 'hsi/actions/queryActions';

//Other
import {EXPORTED_PDF, EXPORTED_CARD, EXPORT_CARD} from 'hsi/constants/actionTypes';

import {handleCSVExport} from 'hsi/utils/export';
import {selectAdditionalQueries, selectLinkedinChannelIds} from 'hsi/selectors';
import {addDrillInToFiltersState} from 'hsi/utils/filters';

import {T} from 'hsi/i18n';
import getConfig from 'hsi/config';
import {ThunkType} from 'hsi/types/redux';
import {AppSource} from 'hsi/types/shared';
import {AvailableFormatType} from 'hsi/components/Card/CardTitle/ExportMenu';
import {QueryContextType} from 'hsi/types/query';
import {SavedSearchMentionApiType} from 'hsi/types/mentions';
import {TimezoneID} from 'hsi/utils/timezones';
import {AllFilteringState} from 'hsi/types/filters';
import {CCFlagsType} from 'hsi/types/cc-flags';
import normaliseSavedSearchMention from 'hsi/utils/mentions/normaliseSavedSearchMention';
import {MentionOrderBySavedsearch} from 'hsi/slices/mentions';
import {CardType} from 'hsi/types/cards';
import {normaliseAPIError} from 'hsi/utils/normaliseApiError'

//Methods
const cleanUpExportPayload = (payload: string[]) => {
    let selectedCards = [...payload];

    if (
        selectedCards.includes('totalVolume') ||
        selectedCards.includes('totalVolumeBySearch') ||
        selectedCards.includes('wordCloud')
    ) {
        selectedCards.push('sideDrawer');
    }

    if (
        selectedCards.includes('emotionHistory') ||
        selectedCards.includes('mentionsHistory') ||
        selectedCards.includes('sentimentHistory')
    ) {
        selectedCards.push('peaks');
    }

    return selectedCards;
};

export function exportCard(
    type: string,
    title: string,
    format: AvailableFormatType,
    queryName: string,
): ThunkType {
    return async (dispatch) => {
        await dispatch({
            type: EXPORT_CARD,
            payload: {
                currentCard: type,
                title,
                format,
                queryName,
                isPortalEnabled: true,
                isExporting: true,
            },
        });
        dispatch(
            showNotification({
                message: T('exportCard.generating', {format: format.toUpperCase()}),
                hidable: false,
                variant: 'info',
                notificationId: 'exportCard',
                anchorOrigin: {vertical: 'bottom', horizontal: 'right'},
            }),
        );
    };
}

//data = data to be parsed as CSV - no idea what format it should be
//dateRange is just a description?
export function exportAsCSV(data: any, dateRange: string, exportTitle?: string): ThunkType {
    return async (dispatch, getState) => {
        const {query} = getState();
        const {exportType, hasBrandwatchLabel} = getConfig();
        const format = 'csv';
        const appName = `${T(`exportCard.header.${exportType}`)}${
            hasBrandwatchLabel ? ` ${T(`exportCard.header.poweredBy`)}` : ''
        }`;
        const queryName = query?.context?.savedSearch?.name;

        await dispatch({
            type: EXPORT_CARD,
            payload: {
                // TODO: Change this to specific state update rather than dumping this in
                format,
                isExporting: true,
            },
        });

        dispatch(
            showNotification({
                message: T('exportCard.generating', {format: format.toUpperCase()}),
                hidable: false,
                variant: 'info',
                notificationId: 'exportCard',
                anchorOrigin: {vertical: 'bottom', horizontal: 'right'},
            }),
        );

        handleCSVExport({
            data,
            queryName,
            dateRange,
            exportTitle,
            appName,
            onSuccess: () => dispatch(exportedCard()),
        });
    };
}

export function exportedCard(): ThunkType {
    return async (dispatch) => {
        dispatch({
            type: EXPORTED_CARD,
        });
        dispatch(hideNotification());
    };
}

export function exportMentionsToCSV( //TODO saved search only
    queryContext: QueryContextType,
    numMentions: number,
): ThunkType {
    return (dispatch, getState) => {
        const state = getState();
        const {
            mentions: {drillInFilter, drillInDates, orderBy, orderIsAsc},
        } = state;
        const additionalQueries = selectAdditionalQueries(state);
        const linkedinChannelIds = selectLinkedinChannelIds(state);

        const filters = addDrillInToFiltersState(
            state.filters as unknown as AllFilteringState,
            drillInFilter,
            drillInDates,
        );

        dispatch(
            showNotification({
                variant: 'info',
                message: T('exportMentionsToCSV.generating'),
                notificationId: 'exportMentionsToCSV',
            }),
        );

        loadSavedSearchMentions({
            queryContext,
            filters,
            orderBy,
            orderIsAsc,
            resultsPage: 0,
            pageSize: numMentions,
            additionalQueries,
            linkedinChannelIds,
            format: 'CSV',
        })
            .then((data) => {
                if (data.status !== 200 || !data.body) {
                    dispatch(
                        showNotification({
                            variant: 'warning',
                            message: T('mentionsContainer.export.noMentionsMsg'),
                        }),
                    );
                } else if (
                    data.body ===
                    '"Error: Unable to export mentions because you have reached your mention export limit"'
                ) {
                    dispatch(
                        showNotification({
                            variant: 'warning',
                            message: T('mentionsContainer.export.exportLimitMsg'),
                        }),
                    );
                } else {
                    //export completed successfully
                    //Add UTF BOM at start of the file to make sure Excel etc correctly interprets the UTF-8
                    const blob = new Blob(['\ufeff' + data.body], {
                        type: 'text/csv',
                    });
                    dispatch(hideNotification());
                    saveAs(blob, 'mentions.csv');
                }
            })
            .catch((e) => {
                console.log('load mentions error', e);
                dispatch(
                    showNotification({
                        variant: 'warning',
                        message: T('mentionsContainer.export.failMsg'),
                    }),
                );
            });
    };
}

export function exportMentionsToPDF( //Does this work with drill-ins
    queryContext: QueryContextType,
    numMentions: number,
    flags: CCFlagsType,
    appSource: AppSource,
    onSuccess?: (numMentions: number) => void,
): ThunkType {
    return async (dispatch, getState) => {
        dispatch(
            showNotification({
                message: T('exportMentionsToPDF.generating'),
                hidable: false,
                variant: 'info',
                notificationId: 'exportMentionsToPDF',
                anchorOrigin: {vertical: 'bottom', horizontal: 'right'},
            }),
        );

        onSuccess?.(numMentions);

        try {
            const state = getState();
            const {mentions, query, search} = state;
            const additionalQueries = selectAdditionalQueries(state);
            const linkedinChannelIds = selectLinkedinChannelIds(state);

            const filters = addDrillInToFiltersState(
                state.filters as unknown as AllFilteringState,
                mentions.drillInFilter,
                mentions.drillInDates,
            );

            //Load mentions
            const loadedMentions = await loadMentionsCursoredPagination({
                queryContext,
                filters,
                orderBy: mentions.orderBy as MentionOrderBySavedsearch,
                orderIsAsc: mentions.orderIsAsc, //boolean
                pageSize: numMentions,
                additionalQueries,
                linkedinChannelIds,
                fullText: false,
            });

            // build export payload
            const appState = {
                // extract unused props (there are loads, but it's not easy to tell what does and does not get used,
                // so I am only removing large props that def aren't needed)
                //Need to case to make TS happy. Not ideal, but necessary without a massive re-think
                mentions: loadedMentions.body.results.map(
                    ({fullText, imageMd5s, imageInfo, entityInfo, ...mention}) =>
                        normaliseSavedSearchMention(mention as SavedSearchMentionApiType),
                ),
                state: {
                    //mentions,
                    query: {context: query.context, additionalQueries},
                    search: {
                        searches: query.context.isMultipleSearch
                            ? (search as any).searches
                            : undefined,
                    },
                    filters: filters as unknown as AllFilteringState,
                },
                flags,
                appSource,
                timezone: (filters?.dateRange?.timezone || query?.context?.timezone) as TimezoneID,
            };

            //console.log(payload.injectData);//very useful for testing/debugging

            const blob = await exportMentionsToPDFService(queryContext.projectId, appState);
            dispatch(hideNotification());
            saveAs(blob, 'mentions.pdf');
        } catch (e) {
            dispatch(
                showNotification({
                    message: T('exportMentionsToPDF.error'),
                    variant: 'warning',
                    anchorOrigin: {vertical: 'top', horizontal: 'center'},
                    hidable: true,
                }),
            );
        }
    };
}

//This is for exporting PDF reports. Probably needs a better name
//TODO will need an updated API, need to check this still all works
export function exportToPDF(
    flags: CCFlagsType,
    appSource: AppSource,
    allCardsLoaded: boolean = false,
    onSuccess?: () => void,
): ThunkType {
    return async (dispatch, getState) => {
        dispatch(
            showNotification({
                message: T('exportToPDF.generating'),
                hidable: false,
                variant: 'info',
                notificationId: 'exportResultsToPDF',
                anchorOrigin: {vertical: 'bottom', horizontal: 'right'},
            }),
        );

        try {
            // make sure all cards are loaded
            if (!allCardsLoaded) {
                await dispatch(loadAllSearchResults());
            }

            // build export payload
            const state = getState();
            const linkedinChannelIds = selectLinkedinChannelIds(state);
            const {chart, results, query, filters, pdfExport, cardTables, user} = state;
            const {projectId, savedSearchId} = query.context;
            dispatch(persistActiveQuery());

            const cardsWithoutData = Object.entries(chart)
                .filter(([_, value]) => isEmpty(value.data))
                .map(([key]) => key) as CardType[];

            const cardsWithData = Object.entries(chart)
                .filter(([_, value]) => !isEmpty(value.data))
                .map(([key]) => key) as CardType[];

            const selectedFilteredCards = pdfExport.cardsToExport[savedSearchId!].filter(
                (card) => !cardsWithoutData.includes(card),
            );

            const selectedCards = pdfExport.cardsToExport[savedSearchId!].length
                ? cleanUpExportPayload(pdfExport.cardsToExport[savedSearchId!])
                : [];

            const appState = {
                state: {
                    chart: selectedCards.length ? pick(chart, selectedCards) : chart,
                    results: selectedCards.length ? pick(results, selectedCards) : results,
                    query: {
                        context: query.context,
                        additionalQueries: query.additionalQueries,
                        linkedinChannelIds,
                    },
                    filters: filters as unknown as AllFilteringState,
                    pdfExport: {
                        ...pdfExport,
                        cardsToExport: {
                            [savedSearchId!]: !isEmpty(selectedFilteredCards)
                                ? selectedFilteredCards
                                : cardsWithData,
                        },
                    },
                    cardTables,
                    user,
                },
                flags,
                appSource,
            };
            /**
             * Useful for debugging. Dump this in src/entries/exports/data.json
             * Then add the env var from src/entries/export/index
             * Then visit localhost:3000/export to see what will be rendered for the PDF
             */

            const blob = await exportReportAsPDF(projectId!, appState);
            onSuccess?.();
            dispatch(
                showNotification({
                    message: T('exportToPDF.success'),
                    hidable: true,
                    variant: 'success',
                    anchorOrigin: {vertical: 'bottom', horizontal: 'right'},
                }),
            );
            setTimeout(() => dispatch(hideNotification), 500);
            saveAs(blob, 'export.pdf');
        } catch (e) {
            const normalisedError = normaliseAPIError(e as any, 'exportToPDF.errors');
            console.error('Export failed', e);
            await dispatch(
                showNotification({
                    message: normalisedError.message,
                    variant: 'warning',
                    anchorOrigin: {vertical: 'top', horizontal: 'center'},
                    hidable: true,
                }),
            );
            setTimeout(() => dispatch(hideNotification), 500); // TODO: Surely we need to call the hideNotification function
        }
    };
}

//TODO proper typing - in fact, this needs a proper set of actions, and maybe belongs in it's own slice
export const saveExportConfig = (exportConfig: any) => ({
    type: EXPORTED_PDF,
    payload: {...exportConfig}, // TODO: We really should define this rather than dumping config in state
});
