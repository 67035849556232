// TODO:
// -Announce sorting changing? Not sure this is something we should be doing, or should be left to the AT? Voiceover doesn't, but maybe others do?

import { ForwardedRef, ReactElement, ReactNode, forwardRef, useEffect, useMemo, useRef } from "react";

//Components
import TruncateWithTooltip from "hsi/components/TruncateWithTooltip";
import PulseLoader from "hsi/components/PulseLoader";
import TableDisplay from "../TableDisplay";

//Hooks
import useAriaAnnounce from "hsi/hooks/useAriaAnnounce";

//Other
import useStyles from './styles';
import { TableSortDir } from "../types";
import { T } from "hsi/i18n";
import { ColumnDefinition, ColumnsDefinition } from "./types";

//Consts
const tooltipProps = {portal: true};

//Types
export type ManagedTableProps<Columns extends string> = {
    columns: ColumnsDefinition<Columns>;
    rowData: Partial<Record<Columns, ReactNode>>[] | ReadonlyArray<Partial<Record<Columns, ReactNode>>>;
    caption: ReactNode;
    components?: Partial<Pick<typeof TableDisplay, 'Head' | 'Body' | 'Row' | 'Foot' | 'Row'| 'TD' | 'TH' | 'SortButton'>>;

    /** If true, will display a loading indicator over the table contents & set the loading style on the TDs */
    loading?: boolean;
    /** Function to return the mesage to be announced the table enters the loading state. A value of null OR a function that returns a value of null = no announced message */
    loadingMsg?: null | ((props: {caption: ReactNode}) => string | null);
    /** Function to return the mesage to be announced the table exits the loading state. A value of null OR a function that returns a value of null = no announced message */
    loadedMsg?: null | ((props: {caption: ReactNode}) => string | null);

    /** Should rows have the 'selectable' style applied? If passed a function, will be called for each row the with row index (zero based), and the return boolean will be used for that row. Defaults to false */
    rowSelectable?: boolean | ((index: number) => boolean);
    /** Optional callback called when a row is clicked. Is called with the row index number (zero based), and the event that triggered the callback. */
    onRowClick?: (index: number, e: Event) => void;
    
    /** Optional callback called when a new sort column is selected. Does not sort data. Is called with the number name, and the event that triggered the callback. */
    onSortClick?: (column: Columns, e: Event) => void;
    /** Optional, used to apply the sort style to a column. Does not sort data. Must be used in conjunction with the 'sortDirection' property to take affect. */
    sortColumn?: Columns;
    /** Optional, used to apply the sort direction style to the currently sorted column. Must be used in conjunction with the 'sortColumn' property to take affect */
    sortDirection?: TableSortDir;
} & Omit<JSX.IntrinsicElements['table'], 'align' | 'bgcolor' | 'border' | 'cellpadding' | 'cellspacing' | 'frame' | 'rules' | 'summary' | 'width' | 'ref'>;


//the component

/**
 * Used to create commonly used tables, hamdles common tasks & requirements.
 * 
 * @param props Component props 
 * @param ref Optional ref
 * @returns 
 */
function ManagedTable<Columns extends string>({
    columns, 
    rowData, 
    caption, 
    components,
    loading = false,
    loadingMsg = defaultLoadingMessage,
    loadedMsg = defaultLoadedMessage,
    rowSelectable, 
    onRowClick, 
    onSortClick, 
    sortColumn, 
    sortDirection, 
    "aria-label": _ariaLabel, 
    ...rest
}: ManagedTableProps<Columns>, ref: ForwardedRef<HTMLTableElement>) {
    const classes = useStyles();

    const Head = components?.Head ?? TableDisplay.Head;
    const Body = components?.Body ?? TableDisplay.Body;
    //const Foot = components?.Foot ?? TableDisplay.Foot;//TODO define footer somehow?
    const Row = components?.Row ?? TableDisplay.Row;
    const TH = components?.TH ?? TableDisplay.TH;
    const TD = components?.TD ?? TableDisplay.TD;
    const SortButton = components?.SortButton ?? TableDisplay.SortButton;

    const tableIsSortable = !!onSortClick && columns.some(({sortable}) => !!sortable);
    const ariaLabel = _ariaLabel || tableIsSortable ? T('managedTable.sortableTableLbl') : undefined;


    const columnWidths = useMemo(() => {
        return (columns as ColumnDefinition<Columns>[]).reduce((output, {width}) => {
            return `${output} ${width || 'auto'}`;
        }, '');
    }, [columns]);

    const headerContent = useMemo(() => {
        return columns.map(({name, label, sortable = false, horizontalAlign, thComponent: ColumnTHComponent = TH, truncateHeaderLabelText}) => {
            const sort = sortColumn === name ? sortDirection : undefined;
            const content = sortable 
                ? <SortButton
                    sort={sort}
                    truncateTextContent={truncateHeaderLabelText}
                    onClick={onSortClick ? (e: any) => onSortClick(name, e) : undefined}
                >{label}</SortButton>
                : truncateHeaderLabelText 
                    ? <TruncateWithTooltip as="span" tooltipProps={tooltipProps}>{label}</TruncateWithTooltip>
                    : label;
            
            return <ColumnTHComponent sort={sort} horizontalAlign={horizontalAlign} key={name} allowContentOverflow={truncateHeaderLabelText}>{content}</ColumnTHComponent>
        });
    }, [SortButton, TH, columns, onSortClick, sortColumn, sortDirection]);

    const bodyContent = useMemo(() => {
        return rowData.map((row, index) => <Row 
            inert={loading? 'inert' : undefined}
            key={index} 
            selectable={rowSelectable === true ? true : !rowSelectable ? undefined : rowSelectable(index)}
            onClick={onRowClick 
                ? (e) => {
                    onRowClick(index, e as unknown as Event);
                } 
                : undefined
            }
        >
            {columns.map(({name, horizontalAlign, tdComponent: ColumnTDComponent = TD, truncateCellText}) => {
                const sort = sortColumn === name ? sortDirection : undefined;

                return <ColumnTDComponent 
                    key={name}
                    sort={sort}
                    horizontalAlign={horizontalAlign}
                    loading={loading}
                    allowContentOverflow={truncateCellText}
                >{truncateCellText 
                    ? <TruncateWithTooltip as="span" tooltipProps={tooltipProps}>{row[name]}</TruncateWithTooltip>
                    : row[name]
                }</ColumnTDComponent>
            })}
        </Row>
    )}, [Row, TD, columns, loading, onRowClick, rowData, rowSelectable, sortColumn, sortDirection]);

    return  <TableDisplay columns={columnWidths} aria-label={ariaLabel} {...rest} ref={ref}>
        <caption className="offscreen">{caption}</caption>
        <Head>
            <Row>
                {headerContent}
            </Row>
        </Head>
        
        <Body className={loading ? classes.loadingBody : undefined}>
            {bodyContent}

            <LoadingIndicator
                loading={loading} 
                numColumns={columns.length} 
                loadingMsg={loadingMsg?.({caption}) ?? null} 
                loadedMsg={loadedMsg?.({caption}) ?? null} 
            />
        </Body>
    </TableDisplay>
}

type LoadingIndicatorProps = {loading?: boolean, numColumns: number, loadingMsg: string | null, loadedMsg: string | null,};

function LoadingIndicator({loading, numColumns, loadingMsg, loadedMsg}: LoadingIndicatorProps) {
    const classes = useStyles();
    const firstLoadRef = useRef(true);//needed to avoid pointless announcements on initial load

    const announce = useAriaAnnounce();
    
    useEffect(() => {
        if(loading) {
            loadingMsg !== null && announce(loadingMsg, 'assertive');
        } else {
            //if initialised with loading = false (the default), do not announce 'loaded' message
            loadedMsg !== null && !firstLoadRef.current && announce(loadedMsg);
        }

        firstLoadRef.current = false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading]);

    return loading 
        ? <TableDisplay.Row className={classes.loadingRow}>
            <TableDisplay.TD colSpan={numColumns} className={classes.loadingCell}>
                <PulseLoader />
            </TableDisplay.TD>
        </TableDisplay.Row> 
        : null
}

export default forwardRef(ManagedTable) as any as <Columns extends string>(p: ManagedTableProps<Columns> & { ref?: ForwardedRef<HTMLTableElement> }) => ReactElement

//Misc
function defaultLoadingMessage({caption}: {caption: ReactNode}) {
    return T('managedTable.loadingMsg', {caption});
}

function defaultLoadedMessage({caption}: {caption: ReactNode}) {
    return T('managedTable.loadedMsg', {caption});
}